export const actions = {
  ADD_COLUMN: "ADD_COLUMN",
  UPDATE_TABLE: "UPDATE_TABLE",
  UPDATE_COLUMN: "UPDATE_COLUMN",
  UPDATE_FILTER: "UPDATE_FILTER",
  UPDATE_TABLE_NAME: "UPDATE_TABLE_NAME",
  UPDATE_TASK_STATUS: "UPDATE_TASK_STATUS",
  REMOVE_COLUMN: "REMOVE_COLUMN",
  SORT_COLUMN: "SORT_COLUMN",
  MOVE_COLUMN: "MOVE_COLUMN",
  TOGGLE_POPOVER: "TOGGLE_POPOVER",
}
