import { IUser } from "@/features/people/types"
import { parseName } from "@/utils/helpers"
import {
  faSignature,
  faSignatureLock,
  faSignatureSlash,
} from "@awesome.me/kit-44b29310a6/icons/classic/light"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Avatar from "../../../components/avatar"

export const PartyDisplayer = ({
  signature,
  should_sign,
  uid,
  user,
}: {
  signature?: Record<string, any>
  should_sign: boolean
  uid: string
  user: IUser
}) => {
  return (
    <div className="relative" key={uid}>
      <Avatar size={40} email={user?.email} tooltip={parseName(user)} />
      <div
        className="bg-slate pointer-events-none absolute -bottom-1 -left-1 flex h-5 w-5 items-center justify-center rounded-full text-black"
        style={{
          background:
            should_sign && signature
              ? "rgb(134, 239, 172)"
              : should_sign && !signature
                ? "rgb(252, 165, 165)"
                : "rgb(216 216 216)",
        }}
      >
        {should_sign ? (
          <div>
            {signature ? (
              <FontAwesomeIcon icon={faSignature} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faSignatureSlash} size="sm" />
            )}
          </div>
        ) : (
          <div>
            <FontAwesomeIcon icon={faSignatureLock} size="sm" />
          </div>
        )}
      </div>
    </div>
  )
}
