import CodeEditor from "@/features/codeEditor"
import {
  FIELD_TYPES,
  FieldTypeIcons,
  FieldTypes,
} from "@/features/fields/utils/constants"
import { IColumn } from "@/types/tables"
import {
  faCheck,
  faChevronDown,
  faTrashAlt,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
} from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Popover } from "antd"
import Image from "next/image"
import { Dispatch, useEffect, useRef, useState } from "react"
import { toast } from "sonner"
import { actions } from "./utils/actions"
import { deleteColumn, updateColumn } from "./utils/api"
import { IAction, ITableState } from "./utils/reducer"
import { DropdownGeneric } from "@/shared/components/fields"
import { DropdownItem } from "@/shared/components/items/DropdownItem"

interface IHeader {
  column: IColumn
  state: ITableState
  dispatch: Dispatch<IAction>
}

const NON_SORTABLE_FIELDS = ["suppliers", "pricings"]

export const Header = ({ column, state, dispatch }: IHeader) => {
  const [header, setHeader] = useState<string>(column.title)
  const [daysRemainingColumn, setDaysRemainingColumn] = useState<IColumn>()
  const [format, setFormat] = useState<string>("")
  const [inputRef, setInputRef] = useState<HTMLInputElement>(null)
  const { title, datatype } = column
  const field = FieldTypes.find((f) => f.type === datatype)
  const closeRef = useRef<() => void>()

  useEffect(() => {
    inputRef?.focus()
    inputRef?.select()
  }, [inputRef])

  useEffect(() => {
    setFormat(column.format || "")
    if (column.daysremainingdataindex !== undefined) {
      setDaysRemainingColumn(
        state.columns.find(
          (col) => col.dataindex === column.daysremainingdataindex,
        ),
      )
    }
  }, [column])

  const onHeaderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setHeader(value)
  }

  const onKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      const updatedColumn = {
        ...column,
        title: header,
        format,
      }
      dispatch({
        type: actions.UPDATE_COLUMN,
        payload: { column: updatedColumn },
      })
      closeRef.current?.()
      await updateColumn(state.uid, column.uid, updatedColumn)
    }
  }

  const onRemove = async () => {
    const columns = [...state.columns]

    dispatch({ type: actions.REMOVE_COLUMN, payload: { key: column.key } })
    await deleteColumn(state.uid, column.uid)

    const columnIndex = columns.findIndex((c) => c.key === column.key)

    if (columnIndex === -1) {
      console.error("Column not found.")
      return
    }

    columns.splice(columnIndex, 1)

    for (let i = columnIndex; i < columns.length; i++) {
      void updateColumn(state.uid, columns[i].uid, columns[i])
    }
  }

  const onSort = (order: "asc" | "desc") => () => {
    let sortColumn = column
    if (column.daysremainingdataindex !== undefined) {
      sortColumn = daysRemainingColumn
    }
    const { datatype, dataindex } = sortColumn
    dispatch({
      type: actions.SORT_COLUMN,
      payload: { defaultsort: { datatype, dataindex, order } },
    })
    closeRef.current?.()
  }

  const onMove = (direction: "left" | "right") => async () => {
    const columns = [...state.columns]
    const found = columns.find((c) => c.key === column.key)
    const from = columns.indexOf(found)
    const to = direction === "left" ? from - 1 : from + 1
    if (to < 0 || to >= columns.length) {
      toast.error("Cannot move column any further")
      return
    }
    const sideColumn = columns[to]

    dispatch({
      type: actions.MOVE_COLUMN,
      payload: { key: column.key, from, to },
    })
    closeRef.current?.()

    await Promise.all([
      updateColumn(state.uid, found.uid, { ...found, position: to }),
      updateColumn(state.uid, sideColumn.uid, {
        ...sideColumn,
        position: from,
      }),
    ])
  }

  const onFormatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setFormat(value)
  }

  const onDaysRemainingColumnChange = async (daysRemainingColumn: IColumn) => {
    setDaysRemainingColumn(daysRemainingColumn)
    const updatedColumn = {
      ...column,
      daysremainingdataindex: daysRemainingColumn.dataindex,
    }
    dispatch({
      type: actions.UPDATE_COLUMN,
      payload: { column: updatedColumn },
    })
    await updateColumn(state.uid, column.uid, updatedColumn)
  }

  const renderFieldOptions = () => {
    switch (column.datatype) {
      case "date":
        return (
          <div>
            <p className="text-xs font-medium">Format</p>
            <input
              className="rounded bg-neutral-100 px-2 py-1 outline-offset-2 outline-blue-500 focus:bg-neutral-100 placeholder:focus:text-inherit"
              type="text"
              value={format}
              onChange={onFormatChange}
              onKeyDown={onKeyDown}
              placeholder="DD/MM/YYYY"
            />
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <Popover
      placement="leftTop"
      trigger="click"
      content={
        <div className="flex min-w-[30rem] flex-col gap-2 overflow-hidden rounded-md p-2 font-normal shadow-lg">
          <input
            ref={setInputRef}
            className="rounded bg-neutral-100 px-2 py-1 outline-offset-2 outline-blue-500 focus:bg-neutral-100 placeholder:focus:text-inherit"
            type="text"
            placeholder="Enter column name"
            name={`column-${header}`}
            id={`column-${header}`}
            value={header}
            onChange={onHeaderChange}
            onKeyDown={onKeyDown}
          />
          {field ? (
            <div className="space-y-2 border-b pb-2">
              <p className="text-xs font-medium">Property type</p>
              <div className="flex items-center gap-2 px-2">
                <FontAwesomeIcon icon={FieldTypeIcons[datatype]} />
                <p>{field?.label}</p>
              </div>
              {renderFieldOptions()}
            </div>
          ) : null}
          {column.key.includes("days_remaining") ? (
            <div className="space-y-2 border-b pb-2">
              <p className="text-xs font-medium">Days remaining column</p>
              <DropdownGeneric
                collection={state.columns.filter(
                  (col) => col.datatype === FIELD_TYPES.DATE,
                )}
                renderItem={({ item }: { item: IColumn }) => (
                  <DropdownItem
                    title={item.title}
                    selected={item.key === daysRemainingColumn?.key}
                    icon={faCheck}
                    onClick={() => {
                      onDaysRemainingColumnChange(item)
                    }}
                  />
                )}
                button={
                  <div className="flex items-center justify-between gap-4">
                    {daysRemainingColumn?.title ? (
                      <p className={"text-sm"}>{daysRemainingColumn.title}</p>
                    ) : (
                      <p className={"text-custom-gray-dark text-sm"}>
                        Select date column
                      </p>
                    )}
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                }
              />
            </div>
          ) : null}
          <button
            onClick={onMove("left")}
            className="flex items-center gap-2 rounded-md px-2 py-1 hover:bg-neutral-100"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <span>Move left</span>
          </button>
          <button
            onClick={onMove("right")}
            className="flex items-center gap-2 rounded-md px-2 py-1 hover:bg-neutral-100"
          >
            <FontAwesomeIcon icon={faArrowRight} />
            <span>Move right</span>
          </button>
          {!NON_SORTABLE_FIELDS.includes(column.datatype) ? (
            <>
              <button
                onClick={onSort("asc")}
                className="flex items-center gap-2 rounded-md px-2 py-1 hover:bg-neutral-100"
              >
                <FontAwesomeIcon icon={faArrowUp} />
                <span>Sort ascending</span>
              </button>
              <button
                onClick={onSort("desc")}
                className="flex items-center gap-2 rounded-md px-2 py-1 hover:bg-neutral-100"
              >
                <FontAwesomeIcon icon={faArrowDown} />
                <span>Sort descending</span>
              </button>
            </>
          ) : null}
          <button
            onClick={onRemove}
            className="flex items-center gap-2 rounded-md px-2 py-1 hover:bg-neutral-100"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
            <span>Delete</span>
          </button>
        </div>
      }
    >
      <div className="flex cursor-pointer items-center gap-2">
        {column.key.startsWith("cvp") ? (
          <Image
            src="/icons/cvp-symbol.png"
            width={14}
            height={14}
            alt="cvp-logo"
          />
        ) : null}
        <p>{title}</p>
      </div>
    </Popover>
  )
}
