import { Skeleton } from "@/shared/components/skeletons/Skeleton"
import { useUser } from "hooks/cvpartner/useUser"
import { ICell } from "../../Cell"

export const DepartmentCell = (props: ICell) => {
  const cvpId = props.record?.cvp_id
  const { user, isLoading, error } = useUser(
    props.record?.type === "INTERNAL" ? cvpId : null,
  )

  if ((error || !user) && !isLoading) {
    return <p className="text-gray-200">No department</p>
  }

  if (isLoading) {
    return <Skeleton className="h-4 w-12" />
  }

  return user?.office_name
}
