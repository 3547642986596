import { Skeleton } from "@/shared/components/skeletons/Skeleton"
import { fetcher } from "@/utils/api"
import { Popover } from "antd"
import { useUser } from "hooks/cvpartner/useUser"
import useSWR from "swr"
import { ICell } from "../../Cell"

export const RolesCell = (props: ICell) => {
  const cvpId = props.record?.cvp_id
  const isInternal = props.record?.type === "INTERNAL"
  const { user } = useUser(isInternal ? cvpId : null)
  const {
    data: roles,
    isLoading,
    error,
  } = useSWR(
    user?.default_cv_id && user?.id
      ? `/cvpartners/cvs/${user.default_cv_id}/${user.id}/roles`
      : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )

  if ((error || !Object.values(roles ?? {}).length) && !isLoading) {
    return <p className="text-gray-200">No roles</p>
  }

  if (isLoading) {
    return <Skeleton className="h-4 w-12" />
  }

  const displayedRoles = []
  const remainingRoles = []

  for (const role in roles) {
    if (roles.hasOwnProperty(role)) {
      if (displayedRoles.length < 3) {
        displayedRoles.push(role)
      } else {
        remainingRoles.push(role)
      }
    }
  }

  const popoverContent = (
    <div className="max-h-40 max-w-sm flex-col flex-wrap overflow-y-auto">
      {remainingRoles.map((tag) => (
        <div
          key={tag}
          className={
            "bg-custom-gray text-custom-gray-dark mb-2 w-fit rounded-md px-2 py-1 text-xs capitalize"
          }
        >
          <p>{tag}</p>
        </div>
      ))}
    </div>
  )

  return (
    <div className="flex w-48 flex-wrap gap-1">
      {displayedRoles.map((role) => (
        <Popover key={role} content={role} placement="top">
          <div
            className={
              "bg-custom-gray text-custom-gray-dark w-40 rounded-md px-2 py-1 text-xs capitalize"
            }
          >
            <p
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {role}
            </p>
          </div>
        </Popover>
      ))}

      {remainingRoles.length > 0 && (
        <Popover content={popoverContent} title="Additional Roles">
          <div
            className={
              "bg-custom-gray text-custom-gray-dark w-10 rounded-md px-2 py-1 text-xs capitalize"
            }
          >
            <p>+{remainingRoles.length}</p>
          </div>
        </Popover>
      )}
    </div>
  )
}
