import { PartyDisplayer } from "@/features/contracts/components/PartyDisplayer"
import { groupBy } from "lodash"
import { ICell } from "../Cell"

export const PartiesCell = ({ value, column }: ICell) => {
  const groupedParties = groupBy(value, "type")
  let partyType: string
  switch (column.datatype) {
    case "owners": {
      partyType = "OWNER"
      break
    }
    case "contract_suppliers": {
      partyType = "SUPPLIER"
      break
    }
    case "talents": {
      partyType = "TALENT"
      break
    }
    case "viewers": {
      partyType = "VIEWER"
      break
    }
  }
  const values = groupedParties?.[partyType] || []
  return (
    <div className="flex">
      {values.map(({ uid, user, signature, should_sign }) => {
        return (
          <PartyDisplayer
            signature={signature}
            should_sign={should_sign}
            key={uid}
            uid={uid}
            user={user}
          />
        )
      })}
    </div>
  )
}
