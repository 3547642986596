import { AgreementOwnerField } from "@/features/fields/components/fields"
import { TagsViewer } from "@/features/tags/components/TagsViewer"
import { parseName } from "@/utils/helpers"
import { ICell } from "../Cell"
import {
  AllocationCell,
  AttachmentsCell,
  CVCell,
  CompanyCell,
  DateCell,
  ExtendedTaskCell,
  FTECell,
  MoneyCell,
  PricingCell,
  ProfilLinkCell,
  ProfileImageCell,
  ProgressCell,
  ResponsiblesCell,
  SelectCell,
  DaysRemainingCell,
  SupplierCell,
  TagsCell,
  TemplateCell,
  TextAreaCell,
  UserFilterCell,
  UserTypeCell,
} from "../cells"
import { CustomerCell } from "../cells/CustomerCell"
import { PartiesCell } from "../cells/PartiesCell"
import { TalentsCell } from "../cells/TalentsCell"
import { RolesCell } from "../cells/cvpartners/RolesCell"
import { TripletexParticipantsCell } from "../cells/tripletex/TripletexParticipantsCell"
import { TripletexResourceCell } from "../cells/tripletex/TripletexResourceCell"

export const cells = {
  textarea: TextAreaCell,
  money: MoneyCell,
  price_range: MoneyCell,
  select: SelectCell,
  multiselect: SelectCell,
  attachments: AttachmentsCell,
  pricings: PricingCell,
  progress_bar: ProgressCell,
  suppliers: SupplierCell,
  agreement_owner: AgreementOwnerField,
  fte: FTECell,
  extended_task: ExtendedTaskCell,
  cv_templates: TemplateCell,
  cvpartner_templates: TemplateCell,
  tags: TagsViewer,
  tripletex_start_date: DateCell,
  tripletex_end_date: DateCell,
  date: DateCell,
  date_range: DateCell,
  multi_date_range: DateCell,
  days_remaining: DaysRemainingCell,
  responsibles: ResponsiblesCell,
  owners: PartiesCell,
  contract_suppliers: PartiesCell,
  talents: PartiesCell,
  viewers: PartiesCell,
  customer: CustomerCell,
  task_talents: TalentsCell,
  people_user: UserFilterCell,
  people_fullname: ({ record }: ICell) => parseName(record),
  people_company: CompanyCell,
  type: UserTypeCell,
  people_allocation: AllocationCell,
  cvp_profile_image: ProfileImageCell,
  cvp_cv: CVCell,
  cvp_tags: TagsCell,
  cvp_profile_link: ProfilLinkCell,
  cvp_roles: RolesCell,
  tripletex_customer: TripletexResourceCell,
  tripletex_project_manager: TripletexResourceCell,
  tripletex_project_category: TripletexResourceCell,
  tripletex_delivery_address: TripletexResourceCell,
  tripletex_department: TripletexResourceCell,
  tripletex_main_project: TripletexResourceCell,
  tripletex_vat_type: TripletexResourceCell,
  tripletex_currency: TripletexResourceCell,
  tripletex_contact: TripletexResourceCell,
  tripletex_attention: TripletexResourceCell,
  tripletex_participants: TripletexParticipantsCell,
}
