import { IColumn, ITable } from "@/types/tables"
import { fetcher } from "@/utils/api"

export const getTables = async () => {
  return await fetcher("/tables")
}

export const createTable = async (table: Partial<ITable>) => {
  return await fetcher("/tables", {
    method: "POST",
    body: JSON.stringify(table),
  })
}

export const updateTable = async (uid: string, table: Partial<ITable>) => {
  return await fetcher(`/tables/${uid}`, {
    method: "PUT",
    body: JSON.stringify(table),
  })
}

export const deleteTable = async (table: string) => {
  return await fetcher(`/tables/${table}`, {
    method: "DELETE",
  })
}

export const addColumn = async (table: string, column: IColumn) => {
  return await fetcher(`/tables/${table}/columns`, {
    method: "POST",
    body: JSON.stringify(column),
  })
}

export const updateColumn = async (
  table: string,
  uid: string,
  column: IColumn,
) => {
  return await fetcher(`/tables/${table}/columns/${uid}`, {
    method: "PUT",
    body: JSON.stringify(column),
  })
}

export const deleteColumn = async (table: string, column: string) => {
  return await fetcher(`/tables/${table}/columns/${column}`, {
    method: "DELETE",
  })
}
