import { ITable } from "@/types/tables"
import { actions } from "./actions"

export type IAction = {
  type: string
  payload?: any
}

export type ITableState = ITable & {
  sorter: {
    datatype?: string
    datandex?: string
    order?: "asc" | "desc"
  }
  showPopover: boolean
}

export const reducer = (state: ITableState, action: IAction): ITableState => {
  switch (action.type) {
    case actions.UPDATE_TABLE:
    case actions.UPDATE_TABLE_NAME:
    case actions.SORT_COLUMN:
    case actions.UPDATE_TASK_STATUS:
    case actions.UPDATE_FILTER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case actions.ADD_COLUMN: {
      const { column } = action.payload
      return {
        ...state,
        columns: [...state.columns, column],
      }
    }
    case actions.UPDATE_COLUMN: {
      const { column } = action.payload
      return {
        ...state,
        columns: state.columns.map((item) => {
          if (item.key === column.key) {
            return column
          }
          return item
        }),
      }
    }
    case actions.REMOVE_COLUMN: {
      const columns = [...state.columns]
      const { key } = action.payload
      const columnIndex = columns.findIndex((column) => column.key === key)

      if (columnIndex === -1) {
        console.error("Column not found.")
        return
      }

      columns.splice(columnIndex, 1)

      for (let i = columnIndex; i < columns.length; i++) {
        columns[i].position -= 1
      }
      return {
        ...state,
        columns,
      }
    }
    case actions.MOVE_COLUMN: {
      const { from, to, key } = action.payload
      const columns = [...state.columns]
      columns[to].position = from
      const column = columns.find((column) => column.key === key)
      columns.splice(from, 1)
      columns.splice(to, 0, { ...column, position: to })
      return {
        ...state,
        columns,
      }
    }
    case actions.TOGGLE_POPOVER: {
      return {
        ...state,
        showPopover: !state.showPopover,
      }
    }
    default: {
      return state
    }
  }
}
